import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const SellMyCar = React.lazy(() => import("./Containers/SellMyCar"));
const SucessFeedBack = React.lazy(() => import("./components/SucessFeedBack"));

const router = createBrowserRouter([
  {
    path: "/IndependentSeller/success",
    element: (
      <Suspense fallback={<></>}>
        <SucessFeedBack />
      </Suspense>
    ),
  },
  {
    path: "/IndependentSeller/popup",
    element: (
      <Suspense fallback={<></>}>
        <SellMyCar />
      </Suspense>
    ),
  },
  {
    path: "/IndependentSeller",
    element: (
      <Suspense fallback={<></>}>
        <SellMyCar />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<></>}>
        <SellMyCar />
      </Suspense>
    ),
  },
]);
const App = (props) => {
  return <RouterProvider router={router} />;
};

export default App;
