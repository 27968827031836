import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#186985",
    },
    secondary: {
      main: "#10924F",
    },
    error: {
      main: "#FAE4E4",
    },
    text: {
      primary: "#07303E",
      secondary: "#626465",
      link: "blue",
    },
  },
  typography: {
    fontFamily: "'Cairo', sans-serif;",
    h1: {
      fontSize: "24px",
      fontWeight: 900,
      lineHeight: 1.75,
    },
    h2: {
      fontSize: "22px",
      fontWeight: 700,
      lineHeight: 1.5,
    },
    60016: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.5,
    },
    80018: {
      fontSize: "18px",
      fontWeight: 800,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        input: ({ theme }) => ({
          // padding: "0 8px!important",
          padding: "13px 8px!important",
          fontSize: "14px",
          fontWeight: 500,
        }),
        inputRoot: ({ theme }) => ({
          // padding: "13px 16px!important",
          padding: "0 16px!important",
          borderRadius: "30px",
          boxShadow: "0px 2px 2px rgba(15, 71, 90, 0.25)",
        }),
        // root: ({ theme }) => ({}),
        // listbox: ({ theme }) => ({}),
        paper: ({ theme }) => ({
          boxShadow: "none",
          marginInline: "-16px",
          borderRadius: 0,
          [theme.breakpoints.up("md")]: {
            marginInline: "-21px",
            transform: "translateX(-21px)",
          },
        }),
        // popper: ({ theme }) => ({
        //   // position: "relative",
        //   // [theme.breakpoints.up("md")]: {
        //   position: "fixed",
        //   minWidth: "100%",
        //   height: "calc(100vh - 205px)",
        //   overflowY: "scroll",
        //   overflowX: "hidden",
        //   // },
        // }),
        listbox: ({ theme }) => ({
          maxHeight: "none",

          // [theme.breakpoints.up("md")]: {
          //   maxHeight: "none",
          // },
        }),
        // popper: ({ theme }) => ({}),
        // option: ({ theme }) => ({}),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "30px",
        },
        input: {
          padding: "11px 14px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          color: "rgb(99,99,99)",
          // border: "1px solid #000000",
          // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
          border: "1px solid #D8D8D8",
          boxShadow: "0px 2px 3px rgba(87, 87, 87, 0.25)",
          fontSize: "12px",
          padding: "15px",
          maxWidth: "327px",
          borderRadius: "3px",
          padding: "7px 13px 7px 26px",
        },
        arrow: {
          "&::before": {
            backgroundColor: "white",
            border: "1px solid #D8D8D8",
            boxShadow: "0px 2px 3px rgba(87, 87, 87, 0.25)",
            width: "95%",
            borderRadius: "3px",
          },
        },
      },
    },
  },
});

export default theme;
